import * as React from "react"

const StyleGuide = () => {
  return (
    <div className="bg--blue">
      <div className="styleguide-page ">
        <h1 className="text-center p-5">Style Guide</h1>
        <section className="container">
          <div className="row">
            <div className="col-lg-8">
              <ol>
                <li className="info-text">
                  <a className="link link-secondary" href="#tips">
                    General tips
                  </a>
                </li>
                <li>
                  <a className="link link-secondary" href="#spacers">
                    Spacers
                  </a>
                </li>
                <li>
                  <a className="link link-secondary" href="#type">
                    Typography
                  </a>
                </li>
                <li>
                  <a className="link link-secondary" href="#ctas">
                    CTAs/Links
                  </a>
                </li>
                <li>
                  <a className="link link-secondary" href="#colors">
                    Colour palette
                  </a>
                </li>
                <li>
                  <a className="link link-secondary" href="#analytic">
                    Analytic
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </section>

        <section className="spacers">
          <div className="bg--yellow color--white mt-5 mb-2">
            <h1 id="spacers" className="container text-start">
              Spacers
            </h1>
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <div>
                  <span>$spacer-xxxs: </span>
                  <span className="color--yellow">8px;</span>
                </div>
                <div>
                  <span>$spacer-xxs: </span>
                  <span className="color--yellow">16px;</span>
                </div>
                <div>
                  <span>$spacer-xs: </span>
                  <span className="color--yellow">24px;</span>
                </div>
                <div>
                  <span>$spacer-s: </span>
                  <span className="color--yellow">32px;</span>
                </div>
                <div>
                  <span>$spacer-m: </span>
                  <span className="color--yellow">40px;</span>
                </div>
                <div>
                  <span>$spacer: </span>
                  <span className="color--yellow">48px;</span>
                </div>
                <div>
                  <span>$spacer-l: </span>
                  <span className="color--yellow">56px;</span>
                </div>
                <div>
                  <span>$spacer-xl: </span>
                  <span className="color--yellow">64px;</span>
                </div>
                <div>
                  <span>$spacer-xxl: </span>
                  <span className="color--yellow">72px;</span>
                </div>
                <div>
                  <span>$spacer-xxxl: </span>
                  <span className="color--yellow">80px;</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="typography">
          <div className="bg--yellow mt-5 mb-2">
            <h1 id="type" className="container text-start">
              Typography
            </h1>
          </div>
          <div className="container ">
            <div className="row">
              <div className="col ">
                <h1>H1 Sample Heading</h1>
                <h2>H2 Sample Heading</h2>
                <h3>H3 Sample Heading</h3>
                <h4>H4 Sample Heading</h4>
                <h6>H6 Sample Heading</h6>
                <p className="pLarge mt-2">Large Paragraph</p>
                <p className="pMedium mt-2">Medium Paragraph</p>
                <p className="pSmall mt-2">Small Paragraph</p>
              </div>
            </div>
          </div>
        </section>

        <section className="ctas">
          <div className="bg--yellow mt-5 mb-4">
            <h1 id="ctas" className="container text-start">
              CTAs/Links
            </h1>
          </div>
          <div className="container">
            <div className="row">
              <div className="col col-3">
                <a className="button link-blue">THE REPORT</a>
              </div>
              <div className="col col-3">
                <a className="button link-pink">Our investigation</a>
              </div>
              <div className="col col-3">
                <a className="button link-yellow">The CV Builder</a>
              </div>
              <div className="col col-3">
                <a className="button link-green">The Anon CV tool</a>
              </div>
            </div>
          </div>
        </section>

        <section className="colors">
          <div className="bg--yellow mt-5 mb-4">
            <h1 id="colors" className="container text-start">
              Colors
            </h1>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="info-text">
                  <p>
                    Below you can find all the colours that hould be used across
                    the website. To use a colour in your SCSS code, please use
                    the provided
                    <code> getColor()</code> function. Here's an example, how to
                    do it:
                  </p>
                  <pre className="bg--dark-grey color--white p-3 mt-3">
                    article
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;background-color: getColor(blue);
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;color: getColor(white);
                    <br />
                  </pre>
                  <p>This works everywhere, so you can do things like:</p>
                  <pre className="bg--dark-grey color--white p-3 mt-3">
                    div
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;background: linear-gradient(to
                    right, getColor(yellow), getColor(dark-yellow));
                    <br />
                  </pre>

                  <p id="colors-list" className="info-text">
                    You can choose from the following colours:
                  </p>
                  <div className="row">
                    <div className="col col-3">
                      <div className="bg--blue border border--white pt-5 my-5">
                        <div className="bg--white p-3">Blue</div>
                      </div>
                    </div>
                    <div className="col col-3">
                      <div className="bg--dark-grey border border--white pt-5 my-5">
                        <div className="bg--white p-3">Dark Grey</div>
                      </div>
                    </div>
                    <div className="col col-3">
                      <div className="bg--maya-blue border border--white pt-5 my-5">
                        <div className="bg--white p-3">Maya Blue</div>
                      </div>
                    </div>
                    <div className="col col-3">
                      <div className="bg--turquoise-blue border border--white pt-5 my-5">
                        <div className="bg--white p-3">Turquoise Blue</div>
                      </div>
                    </div>
                    <div className="col col-3">
                      <div className="bg--green border border--white pt-5 my-5">
                        <div className="bg--white p-3">Green</div>
                      </div>
                    </div>
                    <div className="col col-3">
                      <div className="bg--pink border border--white pt-5 my-5">
                        <div className="bg--white p-3">Pink</div>
                      </div>
                    </div>
                    <div className="col col-3">
                      <div className="bg--yellow border border--white pt-5 my-5">
                        <div className="bg--white p-3">Yellow</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="analytic">
          <div className="bg--yellow mt-5 mb-4">
            <h1 id="analytic" className="container text-start">
              analytic
            </h1>
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="info-text">
                  <p>
                    Below you can find all type of click based tracking that
                    should be used across the website. To use a defaulr tracking
                    in your HTML code, please use
                    <code> data-analytics="true"</code> on an anchor/Link
                    element. Here's an example, how to do it:
                  </p>
                  <pre className="bg--dark-grey color--white p-3 mt-3">
                    <code>
                      <a href="/" data-analytics="true">
                        {" "}
                        Any element on website you need to track on click{" "}
                      </a>
                    </code>
                  </pre>
                  <p>
                    You can also add new custom attribute to generic tracking
                    for example, Please note that it needs{" "}
                    <code> data-analytics_</code>
                  </p>
                  <pre className="bg--dark-grey color--white p-3 mt-3">
                    <code>
                      <a
                        href="/"
                        data-analytics="true"
                        data-analytics_locatationinpage="hero-banner"
                      >
                        {" "}
                        Any element on website you need to track on click{" "}
                      </a>
                    </code>
                  </pre>
                  <p>
                    You can also have custom tracking by replacing entire{" "}
                    <code> data-analytics=</code> with valid JSON, for example
                  </p>
                  <pre className="bg--dark-grey color--white p-3 mt-3">
                    <code>
                      <a
                        href="/"
                        className="btn btn-primary"
                        data-analytics={`{"event":"caseStudyDownload","content_page_title":"Engagement","path": "http://gggole.com", "action_type":"Link Click", "link_text":"Download case study"}`}
                      >
                        {" "}
                        Book your free tickets With Analitics{" "}
                      </a>
                    </code>
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
export default StyleGuide
